<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)

import store from '@/store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText
} from '@fortawesome/vue-fontawesome'

library.add(fas)
library.add(far)
library.add(fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)
Vue.component('font-awesome-layers-text', FontAwesomeLayersText)

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,
ToastificationContent,
    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    currentCenter() {
      return this.$store.getters['center/currentCenterId']
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  created() {
    //alert(window.location.search)
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString);
    //alert(urlParams.get('code'))
    if (urlParams.get('reader') != null ) {
      localStorage.setItem('reader', urlParams.get('reader'))
    }
      this.sockets.subscribe('data', (data) => {
        let json = JSON.parse(data)
        if (this.$store.getters['center/currentCenterId'] == json.center_id && json.action == "update_last_person") {
          console.log("update last person now")
          this.$store.commit('center/UPDATE_LAST_PERSON', json.data)
        //  console.log(json.data)
        }


        if (localStorage.getItem('reader') != null) {
        //  localStorage.setItem('reader_action', 'staff_check_in')
        //  console.log( localStorage.getItem('reader') + " - " + json.reader)
        if ( localStorage.getItem('reader') == json.reader ) {
          var action = localStorage.getItem('reader_action')
        //  console.log(action)
          switch (action) {
            case "staff_check_in":
            //  alert("staff_check_in " + json.reader)
              break;
            case "normal" :
            this.$http.post(process.env.VUE_APP_API_BASE+`/card/`+ json.card + `/entry/`,{ center_id :this.currentCenter ,sn : json.reader})
              .then(res => {
                if (res.data.result === true) {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: res.data.type,
                         icon: res.data.icon,
                         variant: res.data.variant,
                         text: res.data.message,
                       },
                     })

                   } else {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: 'Error',
                         icon: 'XIcon',
                         variant: 'danger',
                         text: res.data.message,
                       },
                     })
                   }
              })

             break;
            case "update_member_card" :
            this.$swal.close()
            this.$http.post(process.env.VUE_APP_API_BASE+`/member/`+ localStorage.getItem('member_id') + `/nfc/`,{ card : json.card })
              .then(res => {
                if (res.data.result === true) {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: 'Success',
                         icon: 'CheckIcon',
                         variant: 'primary',
                         text: res.data.message,
                       },
                     })
                     localStorage.setItem('reader_action', 'normal')
                     localStorage.removeItem('member_id')
                   } else {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: 'Error',
                         icon: 'XIcon',
                         variant: 'danger',
                         text: res.data.message,
                       },
                     })
                     localStorage.setItem('reader_action', 'normal')
                     localStorage.removeItem('member_id')
                   }
              })
              break;
              case "update_user_card" :
            this.$swal.close()
            this.$http.post(process.env.VUE_APP_API_BASE+`/staff/card_update/`+ localStorage.getItem('user_id') + `/`,{ card : json.card })
              .then(res => {
                if (res.data.result === true) {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: 'Success',
                         icon: 'CheckIcon',
                         variant: 'primary',
                         text: res.data.message,
                       },
                     })
                     localStorage.setItem('reader_action', 'normal')
                     localStorage.removeItem('user_id')
                   } else {
                     this.$toast({
                       component: ToastificationContent,
                       position: 'top-center',
                       props: {
                         title: 'Error',
                         icon: 'XIcon',
                         variant: 'danger',
                         text: res.data.message,
                       },
                     })
                     localStorage.setItem('reader_action', 'normal')
                     localStorage.removeItem('user_id')
                   }
              })
              break;
          }
        }
      }
      });



  },

  beforeCreate() {
    var host = window.location.host
    if ( host == "valleyfit.gfs.hk" ) {
      location.href = "https://valleyfitstudio.gfs.hk";
    }
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
}
</script>
